// Import the functions you need from the SDKs you need
import firebase from "firebase";
import "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDYzq-duzB1z0guRmyuMIzvR8zhB6VjTAQ",
    authDomain: "workineo-cse.firebaseapp.com",
    projectId: "workineo-cse",
    storageBucket: "workineo-cse.appspot.com",
    messagingSenderId: "191698780329",
    appId: "1:191698780329:web:9bb67ea278e351a64569b8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();


export { storage, firebase as default };
