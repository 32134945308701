import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavBar from "./components/NavBar";
import ActualiteAdmin from './components/admin/ActualiteAdmin';

const ListActualitesScreen = () => {

    const [actualites, setActualites] = useState([]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const getActualites = async () => {
            if (user.companyId) {
                const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/actualites/company/${user.companyId}`);
                console.log(data)
                setActualites(data);
            }
        }
        getActualites()
    }, []);

    console.log('actualites', actualites);

    return (
        <div className="container align-items-center">
            <NavBar />
            {actualites ? <table className="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Titre</th>
                        <th>Description</th>
                        <th>Image</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {actualites.map((actualite, index) => {
                        return (
                            <ActualiteAdmin key={index} actu={actualite} />
                        )
                    }
                    )}
                </tbody>
            </table> : <div />}

        </div>
    );
};

export default ListActualitesScreen;
