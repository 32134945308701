import React, { useState, useEffect } from "react";
import './App.css';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useNavigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import MultipleImagesUpload from "./components/MultipleImagesUpload";

const CreateCommerceScreen = () => {

    const navigate = useNavigate();

    useEffect(() => {
        //Redirect if not super admin
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.role != 'superadmin')
            navigate('/list-actu');
    }, []);

    const [name, setName] = useState("");
    const [downloadUrls, setDownloadUrls] = useState([]);

    const [subscriptionPack, setSubscriptionPack] = useState("");
    const [horaires, setHoraires] = useState({
        startTimeMonMor: '',
        startTimeTueMor: '',
        startTimeWedMor: '',
        startTimeThuMor: '',
        startTimeFriMor: '',
        startTimeSatMor: '',
        startTimeSunMor: '',
        endTimeMonMor: '',
        endTimeTueMor: '',
        endTimeWedMor: '',
        endTimeThuMor: '',
        endTimeFriMor: '',
        endTimeSatMor: '',
        endTimeSunMor: '',
        startTimeMonAft: '',
        startTimeTueAft: '',
        startTimeWedAft: '',
        startTimeThuAft: '',
        startTimeFriAft: '',
        startTimeSatAft: '',
        startTimeSunAft: '',
        endTimeMonAft: '',
        endTimeTueAft: '',
        endTimeWedAft: '',
        endTimeThuAft: '',
        endTimeFriAft: '',
        endTimeSatAft: '',
        endTimeSunAft: ''
    });

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [website, setWebsite] = useState("");
    const [twitter, setTwitter] = useState("");
    const [instagram, setInstagram] = useState("");
    const [facebook, setFacebook] = useState("");
    const [description, setDescription] = useState("");
    const [displayImage, setDisplayImage] = useState("")
    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null
    });

    const handleSelect = async (value) => {
        const result = await geocodeByAddress(value);
        const latLng = await getLatLng(result[0]);
        setAddress(value);
        setCoordinates(latLng)
    }

    console.log("display", displayImage)
    const handleUpload = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}/api/commerces`, {
                address,
                image: downloadUrls,
                name,
                password,
                startTimeMonMor: horaires.startTimeMonMor,
                startTimeTueMor: horaires.startTimeTueMor,
                startTimeWedMor: horaires.startTimeWedMor,
                startTimeThuMor: horaires.startTimeThuMor,
                startTimeFriMor: horaires.startTimeFriMor,
                startTimeSatMor: horaires.startTimeSatMor,
                startTimeSunMor: horaires.startTimeSunMor,
                startTimeMonAft: horaires.startTimeMonAft,
                startTimeTueAft: horaires.startTimeTueAft,
                startTimeWedAft: horaires.startTimeWedAft,
                startTimeThuAft: horaires.startTimeThuAft,
                startTimeFriAft: horaires.startTimeFriAft,
                startTimeSatAft: horaires.startTimeSatAft,
                startTimeSunAft: horaires.startTimeSunAft,
                endTimeMonAft: horaires.endTimeMonAft,
                endTimeTueAft: horaires.endTimeTueAft,
                endTimeWedAft: horaires.endTimeWedAft,
                endTimeThuAft: horaires.endTimeThuAft,
                endTimeFriAft: horaires.endTimeFriAft,
                endTimeSatAft: horaires.endTimeSatAft,
                endTimeSunAft: horaires.endTimeSunAft,
                endTimeMonMor: horaires.endTimeMonMor,
                endTimeTueMor: horaires.endTimeTueMor,
                endTimeWedMor: horaires.endTimeWedMor,
                endTimeThuMor: horaires.endTimeThuMor,
                endTimeFriMor: horaires.endTimeFriMor,
                endTimeSatMor: horaires.endTimeSatMor,
                endTimeSunMor: horaires.endTimeSunMor,
                email,
                latlng: coordinates,
                notifications: notifs,
                phone,
                subscriptionPack,
                description,
                website,
                twitter,
                facebook,
                instagram,
                displayImage,
                type: commerceType,
                category: commerceCategory,
                subscriptionDate: new Date()
            })
            navigate('/list-commerces')
        } catch (e) {
            console.log(e)
        }
    };


    const [commerceType, setCommerceType] = useState("");
    const [commerceCategory, setCommerceCategory] = useState("");

    const hashMap = {
        1: { type: 'Alimentation', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FAlimentation.png?alt=media&token=7411454b-6826-4e20-87b4-cef2cab959fa' },
        2: { type: 'Animations', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FAnimations.png?alt=media&token=c13708b5-96cf-4277-ae5a-b14802f592ed' },
        3: { type: 'Animaux', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FAnimaux.png?alt=media&token=5ab251cf-5120-41e1-9da5-febb61f1a76a' },
        4: { type: 'Banque Assurance', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FBanque_Assurance.png?alt=media&token=9811c6d4-d830-4ab0-8920-a1761c05c998' },
        5: { type: 'Bijouterie Horlogerie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FBijouterie_Horlogerie.png?alt=media&token=cb937811-2479-4013-a8e3-767339d29e57' },
        6: { type: 'Caviste et Epicerie fine', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FCaviste_et_Epicerie_fine.png?alt=media&token=aec29e78-c4f9-42a7-8071-88746027add8' },
        7: { type: 'Chaussures', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FChaussures.png?alt=media&token=20050c5f-be09-45bf-b6ed-d7b7060a8997' },
        8: { type: 'Chocolatier', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FChocolatier.png?alt=media&token=94659ce0-8f85-4b42-847d-4c3d6d1de12c' },
        9: { type: 'Coiffure', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FCoiffure.png?alt=media&token=74443d69-c465-41fc-b52b-2b233de012b0' },
        10: { type: 'Contrôle technique', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FControle_technique.png?alt=media&token=c3c13953-2520-4979-a8a9-303349a915fe' },
        11: { type: 'Cuisines', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FCuisines.png?alt=media&token=708746fc-4b33-4e17-8234-c329c44de938' },
        12: { type: 'Cycles', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FCycles.png?alt=media&token=ebcc7e66-d44d-46f3-9046-b7d43b16cca6' },
        13: { type: 'Enfants', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FEnfants.png?alt=media&token=08d78790-3023-4f6d-9352-f48ccc71f950' },
        14: { type: 'Fleurs', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FFleurs.png?alt=media&token=3b4aeabd-b83c-432d-b11c-730a06ae4daa' },
        15: { type: 'Garage', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FGarage.png?alt=media&token=354c9849-8ed6-4945-b6cd-f3d3c5ccb0bf' },
        16: { type: 'Gites et chambres d\'hotes', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FGites_et_chambres_dhotes.png?alt=media&token=51230525-e1b5-4c95-8395-50e516828983' },
        17: { type: 'Informatique', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FInformatique.png?alt=media&token=12127f43-ead3-44eb-892d-4c403e402be3' },
        18: { type: 'Librairie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FLibrairie.png?alt=media&token=f0a516bf-e102-40e7-a3af-5238821609da' },
        19: { type: 'Lingerie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FLingerie.png?alt=media&token=6d98941d-f63b-40e8-b43b-7c4483432497' },
        20: { type: 'Location de véhicules', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FLocation_de_vehicules.png?alt=media&token=0261ad53-26b3-4326-8785-095924d58532' },
        21: { type: 'Loisirs', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FLoisirs.png?alt=media&token=a87e2e3a-cf0a-4352-b832-c1b0e6c3cff5' },
        22: { type: 'Magasin de jouets', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMagasin_de_jouets.png?alt=media&token=1195773c-0d23-44b5-a620-d808ac3675bd' },
        23: { type: 'Magasin de sport', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMagasin_de_sport.png?alt=media&token=9011039c-285d-440a-8664-a8d3dfe2eba4' },
        24: { type: 'Maison et cadeaux', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMaison_et_cadeaux.png?alt=media&token=736b8590-24f7-4ffa-931f-1f2a42b0f309' },
        25: { type: 'Maison et services', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMaison_et_services.png?alt=media&token=7b8ae80b-4101-477f-9aed-925e8a73e550' },
        26: { type: 'Mariage et évènements', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMariage_et_evenements.png?alt=media&token=0bb6e89d-fef0-4495-b6a7-6849b4d1b057' },
        27: { type: 'Maroquinerie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMaroquinerie.png?alt=media&token=3629b632-3483-43d9-a35f-38800c830b51' },
        28: { type: 'Médical', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FMedical.png?alt=media&token=40f68095-91b4-44df-b03d-2f50e70841aa' },
        29: { type: 'Opticien', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FOpticien.png?alt=media&token=b1646975-7af8-4fc3-8cf0-2e70921c2b87' },
        30: { type: 'Papeterie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FPapeterie.png?alt=media&token=b54b02b2-15a6-417d-89a8-33b23523e854' },
        31: { type: 'Parfumerie et Institut de beauté', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FParfumerie_et_Institut_de_beaute.png?alt=media&token=29ca8018-586d-4527-9a64-303192f4d008' },
        32: { type: 'Pharmacie', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FPharmacie.png?alt=media&token=58a6f376-c0e8-46fe-9634-d00deb78530f' },
        33: { type: 'Restaurant', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FRestaurant.png?alt=media&token=9768f105-ed52-4e05-8108-f9f2b31f0c1f' },
        34: { type: 'Soin beauté et Minceur', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FSoin_beaute_et_Minceur.png?alt=media&token=dc3299b1-7865-4ddb-8ed2-85b883653780' },
        35: { type: 'Sport', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FSport.png?alt=media&token=6f72643d-f73d-4cef-87f0-d88c02026c96' },
        36: { type: 'Tatouage', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FTatouage.png?alt=media&token=632183f1-b415-4faa-903f-6b84e41dc7f3' },
        37: { type: 'Vêtements', image: 'https://firebasestorage.googleapis.com/v0/b/workineo-cse.appspot.com/o/map_icons%2FV%C3%AAtements.png?alt=media&token=0e729e68-ec6a-4905-a49a-a11a339831be' }
    };

    const handleSelectMaterial = (e) => {
        setCommerceType(hashMap[e.target.value].type)
        setDisplayImage(hashMap[e.target.value].image)
    }

    console.log('type', commerceType)
    const [notifs, setNotifs] = useState(null);

    console.log('downlaod', downloadUrls)
    const packsNotifs = {
        0: "Pack Visibilité",
        12: "Pack Business",
        24: "Pack Premium",
        1: "Pack 1 notification/mois",
        2: "Pack 2 notifications/mois"
    }

    const handleSelectNotifs = (e) => {
        let subscriptionPack = ""
        if (e.target.value === "0") {
            subscriptionPack = "Pack Visibilité"
        } else if (e.target.value === "12") {

            subscriptionPack = "Pack Business"
        } else if (e.target.value === "24") {
            subscriptionPack = "Pack Premium"
        }
        else if (e.target.value === "1") {
            subscriptionPack = "Pack 1 notification/mois"
        }
        else if (e.target.value === "2") {
            subscriptionPack = "Pack 2 notifications/mois"
        }
        setNotifs(e.target.value);
        setSubscriptionPack(subscriptionPack)
    }

    return (
        <div className="App container">
            <NavBar />
            <h5>Créer un commerce</h5>
            <p className="mt-3 mb-1">Email</p>
            <input className="form-control" type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            <p className="mt-3 mb-1">Mot de passe</p>
            <input type="text" className="form-control" placeholder="Mot de passe" onChange={(e) => setPassword(e.target.value)} />
            <p className="mt-3 mb-1">Nom du commerce</p>
            <input type="text" className="form-control" placeholder="Nom du commerce" onChange={(e) => setName(e.target.value)} />
            <p className="mt-3 mb-1">Description</p>
            <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Description" onChange={(e) => setDescription(e.target.value)} rows="8"></textarea>
            <p className="mt-3 mb-1">Site internet</p>
            <input type="text" className="form-control" placeholder="Site internet" onChange={(e) => setWebsite(e.target.value)} />
            <p className="mt-3 mb-1">Twitter</p>
            <input type="text" className="form-control" placeholder="Twitter" onChange={(e) => setTwitter(e.target.value)} />
            <p className="mt-3 mb-1">Instagram</p>
            <input type="text" className="form-control" placeholder="Instagram" onChange={(e) => setInstagram(e.target.value)} />
            <p className="mt-3 mb-1">Facebook</p>
            <input type="text" className="form-control" placeholder="Facebook" onChange={(e) => setFacebook(e.target.value)} />
            <p className="mt-3 mb-1">Adresse</p>
            <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <input {...getInputProps({ placeholder: "Entrez une adresse" })} />

                        <div>
                            {loading ? <div>...loading</div> : null}

                            {suggestions.map((suggestion, index) => {
                                const style = {
                                    backgroundColor: suggestion.active ? "#41b6e6" : "fff"
                                }
                                return (
                                    <div key={index} {...getSuggestionItemProps(suggestion, { style })}>
                                        {suggestion.description}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>

            <p className="mt-3">Image</p>

            <MultipleImagesUpload downloadUrls={downloadUrls} setDownloadUrls={setDownloadUrls} />

            <h5>Horaires d'ouvertures</h5>
            <p>Lundi</p>
            <div style={{ display: 'flex' }}>
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Matin (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeMonMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Matin (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeMonMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Après-midi (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeMonAft: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Après-midi (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeMonAft: e.target.value })} />
            </div>
            <div className="d-flex mb-3">
                <p className="mb-0 mx-2">Fermé</p>
                <input type="checkbox" onChange={(e) => setHoraires({ ...horaires, startTimeMonMor: 'Fermé' })} />
            </div>
            <p>Mardi</p>
            <div style={{ display: 'flex' }}>
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Matin (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeTueMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Matin (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeTueMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Après-midi (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeTueAft: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Après-midi (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeTueAft: e.target.value })} />
            </div>

            <div className="d-flex mb-3">
                <p className="mb-0 mx-2">Fermé</p>
                <input type="checkbox" onChange={(e) => setHoraires({ ...horaires, startTimeTueMor: 'Fermé' })} />
            </div>
            <p>Mercredi</p>
            <div style={{ display: 'flex' }}>
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Matin (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeWedMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Matin (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeWedMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Après-midi (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeWedAft: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Après-midi (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeWedAft: e.target.value })} />
            </div>

            <div className="d-flex mb-3">
                <p className="mb-0 mx-2">Fermé</p>
                <input type="checkbox" onChange={(e) => setHoraires({ ...horaires, startTimeWedMor: 'Fermé' })} />
            </div>
            <p>Jeudi</p>
            <div style={{ display: 'flex' }}>
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Matin (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeThuMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Matin (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeThuMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Après-midi (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeThuAft: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Après-midi (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeThuAft: e.target.value })} />
            </div>

            <div className="d-flex mb-3">
                <p className="mb-0 mx-2">Fermé</p>
                <input type="checkbox" onChange={(e) => setHoraires({ ...horaires, startTimeThuMor: 'Fermé' })} />
            </div>
            <p>Vendredi</p>
            <div style={{ display: 'flex' }}>
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Matin (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeFriMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Matin (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeFriMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Après-midi (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeFriAft: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Après-midi (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeFriAft: e.target.value })} />
            </div>

            <div className="d-flex mb-3">
                <p className="mb-0 mx-2">Fermé</p>
                <input type="checkbox" onChange={(e) => setHoraires({ ...horaires, startTimeFriMor: 'Fermé' })} />
            </div>
            <p>Samedi</p>
            <div style={{ display: 'flex' }}>
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Matin (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeSatMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Matin (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeSatMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Après-midi (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeSatAft: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Après-midi (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeSatAft: e.target.value })} />
            </div>

            <div className="d-flex mb-3">
                <p className="mb-0 mx-2">Fermé</p>
                <input type="checkbox" onChange={(e) => setHoraires({ ...horaires, startTimeSatMor: 'Fermé' })} />
            </div>
            <p>Dimanche</p>
            <div style={{ display: 'flex' }}>
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Matin (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeSunMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Matin (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeSunMor: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire d'ouverture Après-midi (08:15)" onChange={(e) => setHoraires({ ...horaires, startTimeSunAft: e.target.value })} />
                <input className="form-control" type="text" placeholder="Horaire de fermeture Après-midi (17:45)" onChange={(e) => setHoraires({ ...horaires, endTimeSunAft: e.target.value })} />
            </div>

            <div className="d-flex mb-3">
                <p className="mb-0 mx-2">Fermé</p>
                <input type="checkbox" onChange={(e) => setHoraires({ ...horaires, startTimeSunMor: 'Fermé' })} />
            </div>
            <p>Catégorie du commerce</p>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={commerceType}
                label="Age"
                onChange={handleSelectMaterial}
            >
                {Object.entries(hashMap).map((commerce, index) => {
                    return (
                        <MenuItem value={commerce[0]} key={index}>{commerce[1].type}</MenuItem>
                    )
                })}
            </Select>
            <p>Catégorie : {commerceType}</p>
            <p>Sous-type du Commerce</p>
            <input className="form-control" type="text" placeholder="Sous-Type" onChange={(e) => setCommerceCategory(e.target.value)} />


            <p>Téléphone</p>
            <div>
                <input className="form-control" type="text" placeholder="Téléphone" onChange={(e) => setPhone(e.target.value)} />
            </div>
            <p>Notifications</p>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={notifs}
                label="Age"
                onChange={handleSelectNotifs}
            >
                {Object.entries(packsNotifs).map((commerce, index) => {

                    return (
                        <MenuItem value={commerce[0]} key={index}>{commerce[1]}</MenuItem>
                    )
                })}
            </Select>
            <div>
                <button onClick={handleUpload} className="btn btn-primary my-3">Soumettre</button>
            </div>
        </div>
    );
};

export default CreateCommerceScreen;
